import React, {FC, useEffect, useState} from 'react';
import {Input, InputPassword} from "../../../components/FormControls";
import email_svg from "../../../assets/icons/email.svg";
import Button from "../../../components/Button";
import signIn_svg from "../../../assets/icons/signIn.svg";
import google_svg from "../../../assets/icons/google.svg";
import {useGoogleLogin} from "@react-oauth/google";
import {EFieldGroup, ILoginRequest, IUser} from "../../../modules/rest";
import {API} from "../../../modules/api";
import AppStore from "../../../store/AppStore";
import {toast} from "react-toastify";
import {observer} from "mobx-react";
import ChatStore from "../../../store/ChatStore";
import {requestPermissionMessaging} from "../../../modules/firebase";
import {useTranslation} from "react-i18next";
import {toJS} from "mobx";
import {APP_GOOGLE_AUTH} from "../../../modules/constants";
import RegistrationCoins from "./RegistrationCoins";
import {trackEvent} from "../../../modules/utils";

interface Props {
  onFinish: (status: boolean|number) => void;
  onTypeChange: (type: 'login'|'register'|'recovery') => void;
  authType: 'login'|'register'|'recovery'
  sharedChatId?: string;
  modelIdConversation?: string;
}

const AuthSign: FC<Props> = observer(({onFinish, authType, onTypeChange, sharedChatId, modelIdConversation}) => {
  const {t} = useTranslation();
  //@ts-ignore
  const login = useGoogleLogin({
    include_granted_scopes: true,
    ux_mode: 'redirect',
    redirect_uri: window.location.href,
    onSuccess: tokenResponse => googleAuth(tokenResponse),
  });

  const [user, setUser] = useState<ILoginRequest>({email: '', password: ''});
  const [loading, setLoading] = useState(false);

  const query = new URLSearchParams(window.location.search);
  const queryEmail = query.get('email');
  useEffect(() => {
    if (queryEmail) {
      setUser(prevState => ({...prevState, email: queryEmail}));
    }
  }, []);

  const handleChange = (key: keyof ILoginRequest) => (e: any) => {
    setUser(prevState => ({...prevState, [key]: e.target.value}))
  }

  const onSuccess = async (res: { token: string; user: IUser }) => {
    //@ts-ignore
    ym(97677527, 'reachGoal', 'submit2')
    window.localStorage.setItem('token', res.token);
    API.setToken(res.token);
    AppStore.login(res.user);
    let conversationId;
    if (sharedChatId) {
      const res = await API.Conversations.cloneShared(sharedChatId);
      conversationId = res.id;
    } else if (modelIdConversation) {
      const res = await API.Models.startConversation(modelIdConversation);
      conversationId = res.id;
    }

    if (conversationId) {
      await ChatStore.getList(true);
      await ChatStore.getChat(conversationId);
      localStorage.removeItem('conversation');
    }
    if (localStorage.getItem('firebaseMessagingStatus') === 'granted') requestPermissionMessaging();
    onFinish(conversationId || true);
  }

  const googleAuth = async (tokenResponse: any) => {
    if (loading) return;
    try {
      setLoading(true);
      const utm: any = localStorage.getItem('utm') || {};
      const res = await API.Users.loginWithGoogle(
        {accessToken: tokenResponse.access_token, utm: JSON.parse(utm)},
        [EFieldGroup.UserSubscription, EFieldGroup.UserGender]
      );
      if (res.newUser) trackEvent('CompleteRegistration');
      await onSuccess(res);
    } catch (e: any) {
      toast.error(e)
    } finally {
      setLoading(false);
    }
  }

  const handleLogin = async (e: any) => {
    e.preventDefault();
    if (loading) return;
    try {
      setLoading(true);
      let res;
      if (authType === 'register') {
        const utm: any = localStorage.getItem('utm') || {};
        res = await API.Users.register(
          {...user, utm: JSON.parse(utm)},
          [EFieldGroup.UserSubscription, EFieldGroup.UserGender]
        );
        trackEvent('CompleteRegistration')
      } else {
        res = await API.Users.login(user, [EFieldGroup.UserSubscription, EFieldGroup.UserGender]);
      }
      onSuccess(res);
    } catch (e: any) {
      toast.error(e)
    } finally {
      setLoading(false);
    }
  }

  const authTitle = authType === 'register' ? 'CREATE_ACC' : 'SIGN_IN';

  return (
    <>
      <h4 className='mb-4'>{t(authTitle)}</h4>
      <RegistrationCoins visible={authType === 'register'}/>
      <form onSubmit={handleLogin}>
        <Input
          required
          icon={email_svg}
          label='EMAIL'
          className='mb-3'
          placeholder='E-MAIL'
          value={user.email}
          type='email'
          onChange={handleChange('email')}
        />
        <InputPassword
          onForgotPassword={authType === 'login' ? () => onTypeChange('recovery') : undefined}
          required
          value={user.password}
          onChange={handleChange('password')}
        />
        <Button
          className='w-100 mt-4'
          icon={authType !== 'register' && signIn_svg}
          iconAbsolute
          loading={loading}
          title={authTitle}
          type='submit'
        />
        {APP_GOOGLE_AUTH &&
          <>
            <div className='modal-auth-google text-dark-14'>
              <span>{t(authType === 'register' ? 'SIGN_UP_GOOGLE_TEXT' : 'SIGN_IN_GOOGLE_TEXT')}</span>
            </div>
            <Button
              className='w-100'
              icon={google_svg}
              iconAbsolute
              loading={loading}
              title={`${authType === 'register' ? 'SIGN_UP_GOOGLE' : 'SIGN_IN_GOOGLE'}`}
              btnType='white'
              onClick={() => login()}
            />
          </>
        }

        <div className='mt-4 text-dark-14 text-center'>
          {authType === 'register' &&
            <span>{t('ALREADY_ACC')} <span className='text-tint-active'
                                           onClick={() => onTypeChange('login')}>{t('SIGN_IN')}</span></span>
          }
          {authType === 'login' &&
            <span>{t('NO_ACC')} <span className='text-tint-active'
                                      onClick={() => onTypeChange('register')}>{t('SIGN_UP')}</span></span>
          }
        </div>
      </form>
    </>
  );
})

export default AuthSign;