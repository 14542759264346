const hostname = window.location.hostname;

interface TCompanyData {
  APP_COMPANY_NAME: string,
  APP_COMPANY: string,
  APP_COMPANY_ADDRESS: string,
  APP_COMPANY_EMAIL_SUPPORT: string,
  APP_COMPANY_EMAIL_REPORT: string,
  APP_GOOGLE_AUTH: boolean,
}

const defaultCompanyData: TCompanyData = {
  APP_COMPANY_NAME: 'Cherryx.ai',
  APP_COMPANY: 'Silda Group LTD',
  APP_COMPANY_ADDRESS: 'Office 10842 182-184 High Street North East Ham London E6 2JA',
  APP_COMPANY_EMAIL_SUPPORT: 'support@cherryx.ai',
  APP_COMPANY_EMAIL_REPORT: 'report@cherryx.ai',
  APP_GOOGLE_AUTH: true,
}

const companyData: Record<string, TCompanyData> = {
  'cherryx.net': {
    APP_COMPANY_NAME: 'Cherryx.net',
    APP_COMPANY: 'COMMANDERCODE LTD',
    APP_COMPANY_ADDRESS: 'Prodromou, 75, Oneworld Parkview House, 4th floor, 2063, Nicosia, Cyprus',
    APP_COMPANY_EMAIL_SUPPORT: 'support@cherryx.net',
    APP_COMPANY_EMAIL_REPORT: 'report@cherryx.net',
    APP_GOOGLE_AUTH: false,
  }
}

const company: TCompanyData = companyData[hostname] || defaultCompanyData;

export const APP_COMPANY_NAME = company.APP_COMPANY_NAME;
export const APP_COMPANY = company.APP_COMPANY;
export const APP_COMPANY_ADDRESS = company.APP_COMPANY_ADDRESS;
export const APP_COMPANY_EMAIL_SUPPORT = company.APP_COMPANY_EMAIL_SUPPORT;
export const APP_COMPANY_EMAIL_REPORT = company.APP_COMPANY_EMAIL_REPORT;
export const APP_GOOGLE_AUTH = company.APP_GOOGLE_AUTH;

